import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import "./MyAccount.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Axios from "../../Helpers/Interceptors";
import { Button } from "antd";
import Loader from "../Loader/Loader";
const MyAccount = () => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  // Initial state to hold user data from localStorage
  const [initialValues, setInitialValues] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    role:""
  });
  const [userData, setuserData] = useState({});
  const [editProfile, setEditProfile] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  // Load user data from localStorage on component mount
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userData"))?.data?.user;
    console.log(data);
    setImagePreview(data?.imageData)
    setuserData(data);

    if (userData) {
      setInitialValues({
        email: data.email || "",
        firstName: data.firstName || "",
        lastName: data.lastName || "",
        phoneNumber: data.phoneNumber || "",
        role:data.role || ""
      });
    }
  }, []);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required *")
      .email("Email is invalid"),
    firstName: Yup.string()
      .required("First name is required *")
      .min(2, "First name must be at least 2 characters *")
      .matches(/^[A-Za-z ]*$/, "Please enter alphabetic characters only *"),

    lastName: Yup.string()
      .required("Last name is required *")
      .min(2, "Last name must be at least 2 characters *")
      .matches(/^[A-Za-z ]*$/, "Please enter alphabetic characters only *"),

    phoneNumber: Yup.string()

      .matches(/^[0-9]{10}$/, "Please Enter the valid Phone Number *") // Only 10 digits allowed
      .required("Phone number is required *"),

       role: Yup.string()


      .required("Role is required *"),



  });

  const handleSubmit = async (values) => {
    let checkEmail=false
    try {
      setloading(true);


      let obj = {
        ...values,
        id: userData.id,
        role:userData.role,
        imageData:imagePreview

      };
      if(values.email == userData.email){
        checkEmail=true
      }
      let response = await Axios.put("manageUsers/editUser", obj);
      if (response.status == 200) {
        // Checks if status code is 200 (OK)
       // Adjust this based on your API response structure

        // Save the updated user data in localStorage

        if(checkEmail){
          toast.success("Record updated successfully");
          const updatedUserData = response.data;
          const data = JSON.parse(localStorage.getItem("userData"))?.data;
          localStorage.setItem(
            "userData",
            JSON.stringify({
              data: {
                token: data.token,
                timestamp: data.timestamp,
                user: updatedUserData,
              },
            })
          );

          // Update the component state with the new user data
          setuserData(updatedUserData);
          setInitialValues({
            email: updatedUserData.email || "",
            firstName: updatedUserData.firstName || "",
            lastName: updatedUserData.lastName || "",
            phoneNumber: updatedUserData.phoneNumber || "",
            role: updatedUserData.role || "",
          });
        }else{
          localStorage.removeItem('loginTime')
      localStorage.removeItem('userData')
      localStorage.removeItem('token')
            navigate('/')
            toast.error("Email changed Please login again");
        }

        setloading(false);
      } else if (response.status === 422) {
        // Email conflict
        toast.error("Email already exists");
        setloading(false);
      } else if (response.status === 409) {
        // Phone number conflict
        toast.error("Phone number already registered");
        setloading(false);
      } else {
        toast.error("failed to Save. Please try again.");
        setloading(false);
      }

      setEditProfile(false);
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
      setEditProfile(false);
      setloading(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      // Convert the uploaded image to base64
      reader.onload = () => {
        let image = reader.result.split(',')
        setImagePreview(image[1]); // Save base64 image to state
      };

      reader.onerror = (error) => {
        console.error("Error converting file to base64:", error);
      };

      reader.readAsDataURL(file); // Start reading the file
    }
  };

  const dateFormat = (data) => {
    const formattedDate = new Date(data).toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    });

    return formattedDate;
  };
  return (
    <>
      {loading && <Loader />}
      <div className="container-fluid">
        <div className="signup-content1 ">
          <div className="heading-content">
            <h2>My Account</h2>
          </div>
          {!editProfile && (
            <div className="add-button" >
              <Button style={{marginLeft:'90%'}}
                type="primary"
                className="add-user-btn"
                onClick={() => setEditProfile(true)}
              >
                <i className="bx bx-edit"></i> Edit Profile
              </Button>
            </div>
          )}
          <div className="avatar-upload">
            {/* <div className="avatar-edit">
             {editProfile && <input
                type="file"
                id="imageUpload"
                accept=".png, .jpg, .jpeg"
                onChange={handleImageChange}
              />}
              <label htmlFor="imageUpload"></label>
            </div> */}
            {/* <div className="avatar-preview">
              {!imagePreview && <div id="imagePreview"></div>}
              {imagePreview && (
                <div
                  style={{
                    backgroundImage: `url(${'data:image/jpeg;base64,'+imagePreview})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    border: "2px solid #ddd",
                  }}
                ></div>
              )}
            </div> */}
          </div>
          <Formik
            validationSchema={validationSchema}
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values);
              resetForm();
              setEditProfile(false);
            }}
          >
            {({ errors, touched, resetForm, setFieldValue }) => (
              <Form id="signup-form" className="signup-form container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                    <div className="Search-field1" style={{   marginBottom: '0.5rem' }}>
          <label>First Name</label>
                      <Field
                        onChange={(e) =>
                          setFieldValue("firstName", e.target.value.trimStart())
                        }
                        autoComplete="off"
                        className={`form-input ${
                          errors.firstName && touched.firstName ? "error" : ""
                        }`}
                        name="firstName"
                        placeholder="First Name*"
                        disabled={!editProfile}
                      />
                      <ErrorMessage name="firstName">
                        {(msg) => (
                          <span className="error-message">
                            <i className="fa-solid fa-circle-info fa-xl"></i>
                            {msg}
                          </span>
                        )}
                      </ErrorMessage>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                    <div className="Search-field1" style={{   marginBottom: '0.5rem' }}>
          <label>Last Name</label>
                      <Field
                        onChange={(e) =>
                          setFieldValue("lastName", e.target.value.trimStart())
                        }
                        autoComplete="off"
                        className={`form-input ${
                          errors.lastName && touched.lastName ? "error" : ""
                        }`}
                        name="lastName"
                        placeholder="Last Name*"
                        disabled={!editProfile}
                      />
                      <ErrorMessage name="lastName">
                        {(msg) => (
                          <span className="error-message">
                            <i className="fa-solid fa-circle-info fa-xl"></i>
                            {msg}
                          </span>
                        )}
                      </ErrorMessage>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                    <div className="Search-field1" style={{   marginBottom: '0.5rem' }}>
          <label>Phone Number</label>
                      <Field
                        autoComplete="off"
                        className={`form-input ${
                          errors.phoneNumber && touched.phoneNumber
                            ? "error"
                            : ""
                        }`}
                        name="phoneNumber"
                        placeholder="Phone Number*"
                        disabled={!editProfile}
                      />
                      <ErrorMessage name="phoneNumber">
                        {(msg) => (
                          <span className="error-message">
                            <i className="fa-solid fa-circle-info fa-xl"></i>
                            {msg}
                          </span>
                        )}
                      </ErrorMessage>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                    <div className="Search-field1" style={{   marginBottom: '0.5rem' }}>
          <label>Email</label>
                      <Field
                        autoComplete="off"
                        className={`form-input ${
                          errors.email && touched.email ? "error" : ""
                        }`}
                        name="email"
                        placeholder="Email*"
                        disabled={!editProfile}
                      />
                      <ErrorMessage name="email">
                        {(msg) => (
                          <span className="error-message">
                            <i className="fa-solid fa-circle-info fa-xl"></i>
                            {msg}
                          </span>
                        )}
                      </ErrorMessage>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                    <div className="Search-field1" style={{   marginBottom: '0.5rem' }}>
          <label>Role</label>
                      <Field
                        autoComplete="off"
                        className={`form-input ${
                          errors.role && touched.role ? "error" : ""
                        }`}
                        name="role"
                        placeholder="Role*"
                        disabled

                      />
                      <ErrorMessage name="role">
                        {(msg) => (
                          <span className="error-message">
                            <i className="fa-solid fa-circle-info fa-xl"></i>
                            {msg}
                          </span>
                        )}
                      </ErrorMessage>
                      </div>
                    </div>
                  </div>


                  </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mt-2 d-flex">
                      <div style={{ marginRight: "20px" }}>
                        <strong>Last Login :</strong>
                      </div>
                      <div>
                        {JSON.parse(localStorage.getItem("userData"))?.data
                          ? dateFormat(
                              JSON.parse(localStorage.getItem("userData"))?.data
                                ?.timestamp
                            )
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="submit-container">
                  {editProfile ? (
                    <>
                      <button className="submit-button1" type="submit">
                        Update
                      </button>
                      <button
                        type="button"
                        className="submit-button2"
                        onClick={() => {
                          resetForm();
                          setEditProfile(false);
                        }}
                      >
                        Cancel
                      </button>
                    </>
                  ) : null}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default MyAccount;
