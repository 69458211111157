import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import Axios from "../../Helpers/Interceptors";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import "./Login.scss";

import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import {
  getLogoName,
  listenToEvent,
  removeListener,
} from "../../Helpers/utils";

const Login = () => {
  const [Logo, setLogo] = useState(getLogoName);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setloading] = useState(false);
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required *")
      .email("Email is invalid"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters *")
      .required("Password is required *"),
  });

  const handleSubmit = async (values, reset) => {
    try {
      setloading(true);
      const response = await Axios.post("auth/login", values);
      if (response.status === 200) {
        if (response?.data?.passwordStatus?.status == "PASSWORD_EXPIRED") {
          navigate(`/reset-password/${response?.data?.user?.id}/invalid`);
          // toast.success(response?.data?.passwordStatus?.message);
        } else {
          if (
            response?.data?.resetPasswordStatus == "Navigate to Reset Password"
          ) {
            navigate(`/reset-password/${response?.data?.user?.id}/invalid`);
          } else {
            toast.success("User Login successfull !");
            localStorage.setItem("userData", JSON.stringify(response));
            localStorage.setItem("token", JSON.stringify(response.data.token));
            const loginTime = new Date().getTime(); // Current time in milliseconds
            localStorage.setItem("loginTime", loginTime);
            navigate("/Dashboard");
            reset();
          }
        }
      } else if (response.status == 401 || response.status == 403) {
        toast.error(response.response.data.message);
      }
      setloading(false);
    } catch (error) {
      // Handle CORS error or other network issues
      console.error("Error:", error);

      // If error is a CORS issue, you might not have a response object
      if (error.response) {
        toast.error(
          `Error: ${
            error.response.data.message ||
            "An error occurred. Please try again later."
          }`
        );
      } else if (error.request) {
        toast.error(
          "Request was made but no response was received. This could be a CORS issue or network problem."
        );
      } else {
        toast.error("An error occurred. Please try again later.");
      }
      toast.error(
        "Request was made but no response was received. This could be a CORS issue or network problem."
      );
      setloading(false);
    }
  };

  useEffect(() => {
    document.getElementById("email").setAttribute("autocomplete", "off");
    document
      .getElementById("password")
      .setAttribute("autocomplete", "new-password");
    setLogo(getLogoName);
    const handleCustomEvent = (e) => {
      setLogo(getLogoName); // Get the event data from e.detail
    };

    listenToEvent("customEvent", handleCustomEvent); // Listen for the event

    // Cleanup the event listener when ComponentB is unmounted
    return () => {
      removeListener("customEvent", handleCustomEvent); // Remove the listener
    };

    // Dynamically set autocomplete attributes to prevent autofill suggestions
  }, []);

  const Focus = (event) => {
    var foo = document.querySelector("input");
    foo.setAttribute("autoComplete", "off");
  };

  return (
    <>
      {loading && <Loader />}
      <section className="signup">
        <div className="container-size">
          <div className="signup-content">
            <Formik
              enableReinitialize
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => {
                handleSubmit(values, resetForm);
              }}
            >
              {({ errors, touched }) => (
                <Form id="signup-form" className="signup-form">
                  <div className="register-logo">
                    <div className="logo-resize">
                      <img
                        src="/images/doctor-logo1.png"
                        style={{ height: "65px" }}
                        alt="Logo"
                      />
                      {/* <h2 class="first-txt">{Logo}</h2> */}
                    </div>

                    <h2>Admin Portal Login</h2>
                  </div>

                  <div className="form-group">
                    <Field
                      className={`form-input ${
                        errors.email && touched.email ? "error" : ""
                      }`}
                      name="email"
                      id="email"
                      placeholder="Email*"
                      onFocus={Focus}
                      autoComplete="new-password"
                    />
                    <ErrorMessage name="email">
                      {(msg) => (
                        <span className="error-message">
                          <i className="fa-solid fa-circle-info fa-xl"></i>
                          {msg}
                        </span>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className="form-group">
                    <div className="input-group">
                      <Field
                        className={`form-input ${
                          errors.password && touched.password ? "error" : ""
                        }`}
                        name="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password*"
                        id="password"
                        onFocus={Focus}
                        autoComplete="nope"
                      />
                      <i
                        className={`far ${
                          showPassword ? "fa-eye" : "fa-eye-slash"
                        }`}
                        onClick={() => setShowPassword(!showPassword)}
                        style={{
                          cursor: "pointer",
                          marginLeft: "-30px",
                          marginTop: "24px",
                        }}
                      ></i>
                    </div>
                    <ErrorMessage name="password">
                      {(msg) => (
                        <span className="error-message">
                          {/* <i className="fa-solid fa-circle-info fa-xl"></i> */}
                          {msg}
                        </span>
                      )}
                    </ErrorMessage>
                    <div className="forgot-password">
                      <Link to="/forgot-password" className="forgot-link">
                        Forgot Password?
                      </Link>
                    </div>
                  </div>

                  <div className="form-group">
                    <button className="form-submit" type="submit">
                      Log in
                    </button>
                  </div>

                  <p className="loginhere">
                    Don't have an account?{" "}
                    <Link className="loginhere-link" to="/register">
                      Signup here
                    </Link>
                  </p>
                  <p className="loginhere1">
                    Link to Book Appointment Portal:{" "}
                    <a
                      className="loginhere-link"
                      href="https://vetconnex.tscti.com/"
                      target="_self"
                    >
                      Vetconnex
                    </a>
                  </p>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
